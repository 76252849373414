/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { ReportState, State } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { IContact } from '../../../../interfaces/src/v2';
import captureExceptionInSentry from './errorHandler';
import { ContactType } from '@/enums/ContactType';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const ReportModule = {
  state(): ReportState {
    return {
      marketingCampaignsReport: [],
      loadingMarketingCampaignsReport: false,
      recruitmentCampaignsReport: [],
      loadingRecruitmentCampaignsReport: false,
      mallMemosReport: [],
      loadingMallMemosReport: false,
      storeMemosReport: [],
      loadingStoreMemosReport: false,
      mallAdminMarketingCampaignsReport: [],
      loadingMallAdminMarketingCampaignsReport: false,
      mallAdminRecruitmentCampaignsReport: [],
      loadingMallAdminRecruitmentCampaignsReport: false,
      opportunitiesReport: [],
      loadingOpportunitiesReport: false,
    };
  },

  mutations: {
    clearAllReports(state: ReportState): void {
      state.marketingCampaignsReport = [];
      state.recruitmentCampaignsReport = [];
      state.mallMemosReport = [];
      state.storeMemosReport = [];
      state.mallAdminMarketingCampaignsReport = [];
      state.mallAdminRecruitmentCampaignsReport = [];
      state.opportunitiesReport = [];
    },
    updateMarketingCampaignsReport(state: ReportState, payload: any[]): void {
      state.marketingCampaignsReport = payload;
    },
    updateLoadingMarketingCampaignsReport(state: ReportState, payload: boolean): void {
      state.loadingMarketingCampaignsReport = payload;
    },
    updateRecruitmentCampaignsReport(state: ReportState, payload: any[]): void {
      state.recruitmentCampaignsReport = payload;
    },
    updateLoadingRecruitmentCampaignsReport(state: ReportState, payload: boolean): void {
      state.loadingRecruitmentCampaignsReport = payload;
    },
    updateMallMemosReport(state: ReportState, payload: any[]): void {
      state.mallMemosReport = payload;
    },
    updateLoadingMallMemosReport(state: ReportState, payload: boolean): void {
      state.loadingMallMemosReport = payload;
    },
    updateStoreMemosReport(state: ReportState, payload: any[]): void {
      state.storeMemosReport = payload;
    },
    updateLoadingStoreMemosReport(state: ReportState, payload: boolean): void {
      state.loadingStoreMemosReport = payload;
    },
    updateMallAdminMarketingCampaignsReport(state: ReportState, payload: any[]): void {
      state.mallAdminMarketingCampaignsReport = payload;
    },
    updateLoadingMallAdminMarketingCampaignsReport(state: ReportState, payload: boolean): void {
      state.loadingMallAdminMarketingCampaignsReport = payload;
    },
    updateMallAdminRecruitmentCampaignsReport(state: ReportState, payload: any[]): void {
      state.mallAdminRecruitmentCampaignsReport = payload;
    },
    updateLoadingMallAdminRecruitmentCampaignsReport(state: ReportState, payload: boolean): void {
      state.loadingMallAdminRecruitmentCampaignsReport = payload;
    },
    updateOpportunitiesReport(state: ReportState, payload: any[]): void {
      state.opportunitiesReport = payload;
    },
    updateLoadingOpportunitiesReport(state: ReportState, payload: boolean): void {
      state.loadingOpportunitiesReport = payload;
    },
  },

  actions: {
    clearAllReports(context: ActionContext<ReportState, State>): void {
      context.commit('clearAllReports');
    },
    async fetchMarketingCampaignsReports(context: ActionContext<ReportState, State>): Promise<any> {
      context.commit('updateLoadingMarketingCampaignsReport', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMarketingCampaignsReport(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.closedStores,
        );
        records?.data?.data?.forEach((report: any) => {
          report.ownerDesc = report?.mallOwner?.description;
          report.brandCount = report?.stats?.brandCount;
          report.brandList = report?.stats?.brandNames;
          report.supportsMarketing = report?.stats?.supportsMarketing;
          report.averageResponseTime = report?.stats?.averageResponseTime !== '0 Sec' ? report?.stats?.averageResponseTime : '';
          report.lastAnswerDate = report?.stats?.lastAnswerDate;
          report.lastSentDate = report?.stats?.lastSentDate;
          report.statusDesc = report?.status?.description;
          report.brandDesc = report?.brand?.description;
          report.mallOwnerDesc = report?.mall?.mallOwner?.description;
          report.mallName = report?.mall?.description;
          report.socialStats = {
            approved: report?.stats?.socialApproved,
            declined: report?.stats?.socialDeclined,
            pending: report?.stats?.socialPending,
            sent: report?.stats?.socialSent,
          };
          report.localIsTo = false;
          const contactList: any[] = [];
          let contacts = [];
          if (report?.mall?.contacts?.length > 0) {
            contacts = report?.mall?.contacts;
          } else if (report?.contacts?.length > 0) {
            contacts = report?.contacts;
          }
          contacts.forEach((contact: IContact) => {
            if (contact?.contactTypeId === ContactType['Local Contact']) {
              if (contact?.emailType === 'to') {
                report.localIsTo = true;
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
              if (contact?.emailType === 'cc' && !report.localIsTo) {
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
            } else {
              if (contact?.email) {
                contactList.push(contact.email);
              }
            }
          });
          const data: any = [];
          const labels: any = [];
          const borderColor: any = [];
          const backgroundColor: any = [];

          if (report?.chartData?.length > 0) {
            report.participatedPercent = Math.round(
              (report?.chartData?.filter((item: any) => item.participated).length / report?.chartData?.length) * 100,
            );
          } else {
            report.participatedPercent = 0;
          }

          if (report?.chartData?.length < 5) {
            for (let i = report?.chartData?.length; i < 5; i++) {
              data.push(0);
              labels.push(0);
              borderColor.push('');
              backgroundColor.push('');
            }
          }

          report?.chartData?.forEach((item: any) => {
            data.push(item.participated ? 100 : 1);
            labels.push(item.campaignNumber);
            borderColor.push(item.participated ? 'green' : 'red');
            backgroundColor.push(item.participated ? 'green' : 'red');
          });

          report.performance = {
            datasets: [
              {
                label: 'Average',
                data: data,
                borderColor: borderColor,
                backgroundColor: backgroundColor,
                fill: true,
              },
            ],
            labels: labels,
          };
          report.contactList = contactList;
          report.benchmark =
            report?.mall?.stats?.percentApproved >= 0 ? Math.round(report?.mall?.stats?.percentApproved) : Math.round(report?.stats?.percentApproved);
          report.overallApprovalLast5 =
            report?.mall?.stats?.percentLast5Approved >= 0
              ? Math.round(report?.mall?.stats?.percentLast5Approved)
              : Math.round(report?.stats?.percentLast5Approved);
        });
        context.commit('updateMarketingCampaignsReport', records.data.data);
        context.commit('updateLoadingMarketingCampaignsReport', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMarketingCampaignsReport', false);
      }
    },
    async fetchRecruitmentCampaignsReports(context: ActionContext<ReportState, State>): Promise<any> {
      context.commit('updateLoadingRecruitmentCampaignsReport', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getRecruitmentCampaignsReport(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.closedStores,
        );
        records?.data?.data?.forEach((report: any) => {
          report.ownerDesc = report?.mallOwner?.description;
          report.brandCount = report?.stats?.brandCount;
          report.brandList = report?.stats?.brandNames;
          report.supportsMarketing = report?.stats?.supportsMarketing;
          report.averageResponseTime = report?.stats?.averageResponseTime !== '0 Sec' ? report?.stats?.averageResponseTime : '';
          report.lastAnswerDate = report?.stats?.lastAnswerDate;
          report.lastSentDate = report?.stats?.lastSentDate;
          report.statusDesc = report?.status?.description;
          report.brandDesc = report?.brand?.description;
          report.mallOwnerDesc = report?.mall?.mallOwner?.description;
          report.mallName = report?.mall?.description;
          report.socialStats = {
            approved: report?.stats?.socialApproved,
            declined: report?.stats?.socialDeclined,
            pending: report?.stats?.socialPending,
            sent: report?.stats?.socialSent,
          };
          report.localIsTo = false;
          const contactList: any[] = [];
          let contacts = [];
          if (report?.mall?.contacts?.length > 0) {
            contacts = report?.mall?.contacts;
          } else if (report?.contacts?.length > 0) {
            contacts = report?.contacts;
          }
          contacts.forEach((contact: IContact) => {
            if (contact?.contactTypeId === ContactType['Local Contact']) {
              if (contact?.emailType === 'to') {
                report.localIsTo = true;
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
              if (contact?.emailType === 'cc' && !report.localIsTo) {
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
            } else {
              if (contact?.email) {
                contactList.push(contact.email);
              }
            }
          });
          const data: any = [];
          const labels: any = [];

          if (report?.chartData?.length > 0) {
            report.participatedPercent = Math.round(
              (report?.chartData?.filter((item: any) => item.participated).length / report?.chartData?.length) * 100,
            );
          } else {
            report.participatedPercent = 0;
          }

          if (report?.chartData?.length < 5) {
            for (let i = report?.chartData?.length; i < 5; i++) {
              data.push(0);
              labels.push(0);
            }
          }
          report?.chartData?.forEach((item: any) => {
            data.push(item.participated ? 100 : 1);
            labels.push(item.campaignNumber);
          });
          report.performance = {
            datasets: [
              {
                label: 'Average',
                data: data,
                borderColor: '#707070',
                backgroundColor: '#707070',
                fill: true,
              },
            ],
            labels: labels,
          };
          report.contactList = contactList;
          report.benchmark =
            report?.mall?.stats?.percentApproved >= 0 ? Math.round(report?.mall?.stats?.percentApproved) : Math.round(report?.stats?.percentApproved);
          report.overallApprovalLast5 =
            report?.mall?.stats?.percentLast5Approved >= 0
              ? Math.round(report?.mall?.stats?.percentLast5Approved)
              : Math.round(report?.stats?.percentLast5Approved);
        });
        context.commit('updateRecruitmentCampaignsReport', records.data.data);
        context.commit('updateLoadingRecruitmentCampaignsReport', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingRecruitmentCampaignsReport', false);
      }
    },
    async fetchMallMemosReports(context: ActionContext<ReportState, State>): Promise<any> {
      context.commit('updateLoadingMallMemosReport', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallMemosReport(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.closedStores,
        );
        records?.data?.data?.forEach((report: any) => {
          report.ownerDesc = report?.mallOwner?.description;
          report.brandCount = report?.stats?.brandCount;
          report.brandList = report?.stats?.brandNames;
          report.supportsMarketing = report?.stats?.supportsMarketing;
          report.averageResponseTime = report?.stats?.averageResponseTime !== '0 Sec' ? report?.stats?.averageResponseTime : '';
          report.lastAnswerDate = report?.stats?.lastAnswerDate;
          report.lastSentDate = report?.stats?.lastSentDate;
          report.statusDesc = report?.status?.description;
          report.brandDesc = report?.brand?.description;
          report.mallOwnerDesc = report?.mall?.mallOwner?.description;
          report.mallName = report?.mall?.description;
          report.localIsTo = false;
          const contactList: any[] = [];
          let contacts = [];
          if (report?.mall?.contacts?.length > 0) {
            contacts = report?.mall?.contacts;
          } else if (report?.contacts?.length > 0) {
            contacts = report?.contacts;
          }
          contacts.forEach((contact: IContact) => {
            if (contact?.contactTypeId === ContactType['Local Contact']) {
              if (contact?.emailType === 'to') {
                report.localIsTo = true;
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
              if (contact?.emailType === 'cc' && !report.localIsTo) {
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
            } else {
              if (contact?.email) {
                contactList.push(contact.email);
              }
            }
          });
          const data: any = [];
          const labels: any = [];

          if (report?.chartData?.length > 0) {
            report.participatedPercent = Math.round(
              (report?.chartData?.filter((item: any) => item.participated).length / report?.chartData?.length) * 100,
            );
          } else {
            report.participatedPercent = 0;
          }

          if (report?.chartData?.length < 5) {
            for (let i = report?.chartData?.length; i < 5; i++) {
              data.push(0);
              labels.push(0);
            }
          }
          report?.chartData?.forEach((item: any) => {
            data.push(item.participated ? 100 : 1);
            labels.push(item.campaignNumber);
          });

          report.performance = {
            datasets: [
              {
                label: 'Average',
                data: data,
                borderColor: '#707070',
                backgroundColor: '#707070',
                fill: true,
              },
            ],
            labels: labels,
          };
          report.contactList = contactList;
          report.benchmark =
            report?.mall?.stats?.percentApproved >= 0 ? Math.round(report?.mall?.stats?.percentApproved) : Math.round(report?.stats?.percentApproved);
          report.overallApprovalLast5 =
            report?.mall?.stats?.percentLast5Approved >= 0
              ? Math.round(report?.mall?.stats?.percentLast5Approved)
              : Math.round(report?.stats?.percentLast5Approved);
        });
        context.commit('updateMallMemosReport', records.data.data);
        context.commit('updateLoadingMallMemosReport', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMallMemosReport', false);
      }
    },
    async fetchStoreMemosReports(context: ActionContext<ReportState, State>): Promise<any> {
      context.commit('updateLoadingStoreMemosReport', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getStoreMemosReport(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.closedStores,
        );
        records?.data?.data?.forEach((report: any) => {
          report.ownerDesc = report?.mallOwner?.description;
          report.brandCount = report?.stats?.brandCount;
          report.brandList = report?.stats?.brandNames;
          report.supportsMarketing = report?.stats?.supportsMarketing;
          report.averageResponseTime = report?.stats?.averageResponseTime !== '0 Sec' ? report?.stats?.averageResponseTime : '';
          report.lastAnswerDate = report?.stats?.lastAnswerDate;
          report.lastSentDate = report?.stats?.lastSentDate;
          report.statusDesc = report?.status?.description;
          report.brandDesc = report?.brand?.description;
          report.mallOwnerDesc = report?.mall?.mallOwner?.description;
          report.mallName = report?.mall?.description;
          report.mallId2 = report?.mall?.id;
          const contactList: any[] = [];
          report?.contacts?.forEach((contact: IContact) => {
            if (contact?.contactTypeId === ContactType['Store Manager']) {
              report.storeManagerName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
              report.storeManagerEmail = contact.email ?? '';
            } else {
              if (contact?.email) {
                contactList.push(contact.email);
              }
            }
          });
          const data: any = [];
          const labels: any = [];

          if (report?.chartData?.length > 0) {
            report.participatedPercent = Math.round(
              (report?.chartData?.filter((item: any) => item.participated).length / report?.chartData?.length) * 100,
            );
          } else {
            report.participatedPercent = 0;
          }

          if (report?.chartData?.length < 5) {
            for (let i = report?.chartData?.length; i < 5; i++) {
              data.push(0);
              labels.push(0);
            }
          }
          report?.chartData?.forEach((item: any) => {
            data.push(item.participated ? 100 : 1);
            labels.push(item.campaignNumber);
          });
          report.performance = {
            datasets: [
              {
                label: 'Average',
                data: data,
                borderColor: '#707070',
                backgroundColor: '#707070',
                fill: true,
              },
            ],
            labels: labels,
          };
          report.contactList = contactList;
          report.benchmark =
            report?.mall?.stats?.percentApproved >= 0 ? Math.round(report?.mall?.stats?.percentApproved) : Math.round(report?.stats?.percentApproved);
          report.overallApprovalLast5 =
            report?.mall?.stats?.percentLast5Approved >= 0
              ? Math.round(report?.mall?.stats?.percentLast5Approved)
              : Math.round(report?.stats?.percentLast5Approved);
        });
        context.commit('updateStoreMemosReport', records.data.data);
        context.commit('updateLoadingStoreMemosReport', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingStoreMemosReport', false);
      }
    },
    async fetchMallAdminMarketingCampaignsReports(context: ActionContext<ReportState, State>): Promise<any> {
      console.log('IN MALL ADMIN MARKETING REPORTS ACTION');
      context.commit('updateLoadingMallAdminMarketingCampaignsReport', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallAdminMarketingCampaignsReport(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.closedStores,
        );
        records?.data?.data?.forEach((report: any) => {
          report.averageResponseTime = report?.stats?.averageResponseTime !== '0 Sec' ? report?.stats?.averageResponseTime : '';
          report.lastAnswerDate = report?.stats?.lastAnswerDate;
          report.lastSentDate = report?.stats?.lastSentDate;
          report.statusDesc = report?.status?.description;
          report.brandDesc = report?.brand?.description;
          report.companyDesc = report?.company?.description;
          report.mallDesc = report?.mall?.description;
          const contactList: any[] = [];
          report?.contacts?.forEach((contact: IContact) => {
            if (contact?.contactTypeId === ContactType['Store Manager']) {
              report.storeManagerName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
              report.storeManagerEmail = contact.email ?? '';
            } else {
              if (contact?.email) {
                contactList.push(contact.email);
              }
            }
          });
        });
        console.log('RECORDS: ', records?.data?.data);
        context.commit('updateMallAdminMarketingCampaignsReport', records.data.data);
        context.commit('updateLoadingMallAdminMarketingCampaignsReport', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMallAdminMarketingCampaignsReport', false);
      }
    },
    async fetchMallAdminRecruitmentCampaignsReports(context: ActionContext<ReportState, State>): Promise<any> {
      console.log('IN MALL ADMIN RECRUITMENT REPORTS ACTION');
      context.commit('updateLoadingMallAdminRecruitmentCampaignsReport', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getMallAdminRecruitmentCampaignsReport(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.closedStores,
        );
        records?.data?.data?.forEach((report: any) => {
          report.averageResponseTime = report?.stats?.averageResponseTime !== '0 Sec' ? report?.stats?.averageResponseTime : '';
          report.lastAnswerDate = report?.stats?.lastAnswerDate;
          report.lastSentDate = report?.stats?.lastSentDate;
          report.statusDesc = report?.status?.description;
          report.brandDesc = report?.brand?.description;
          report.companyDesc = report?.company?.description;
          report.mallDesc = report?.mall?.description;
          const contactList: any[] = [];
          report?.contacts?.forEach((contact: IContact) => {
            if (contact?.contactTypeId === ContactType['Store Manager']) {
              report.storeManagerName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
              report.storeManagerEmail = contact.email ?? '';
            } else {
              if (contact?.email) {
                contactList.push(contact.email);
              }
            }
          });
        });
        console.log('RECORDS: ', records?.data?.data);
        context.commit('updateMallAdminRecruitmentCampaignsReport', records.data.data);
        context.commit('updateLoadingMallAdminRecruitmentCampaignsReport', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingMallAdminRecruitmentCampaignsReport', false);
      }
    },
    async fetchOpportunitiesReports(context: ActionContext<ReportState, State>): Promise<any> {
      context.commit('updateLoadingOpportunitiesReport', true);
      const payload = context.rootState['PaginationModule'];
      try {
        const records: AxiosResponse<Response> = await new HttpService().getOpportunitiesReport(
          payload?.page,
          payload?.limit,
          payload?.search,
          payload?.sortKey,
          payload?.sortDirection,
          payload?.closedStores,
        );
        records?.data?.data?.forEach((report: any) => {
          report.ownerDesc = report?.mallOwner?.description;
          report.statusDesc = report?.status?.description;
          report.brandList = report?.stats?.brandNames;
          report.brandDesc = report?.brand?.description;
          report.mallOwnerDesc = report?.mall?.mallOwner?.description;
          report.mallName = report?.mall?.description;
          report.numberOpportunitiesReceived = report?.stats?.numberOpportunitiesReceived;
          report.lastOpportunityReceivedDate = report?.stats?.lastOpportunityReceivedDate;
          const contactList: any[] = [];
          let contacts = [];
          if (report?.mall?.contacts?.length > 0) {
            contacts = report?.mall?.contacts;
          } else if (report?.contacts?.length > 0) {
            contacts = report?.contacts;
          }
          contacts?.forEach((contact: IContact) => {
            if (contact?.contactTypeId === ContactType['Local Contact']) {
              if (contact?.emailType === 'to') {
                report.localIsTo = true;
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
              if (contact?.emailType === 'cc' && !report.localIsTo) {
                report.localContactName = `${contact.firstName}${contact?.lastName ? ' ' + contact.lastName : ''}`;
                report.localContactEmail = contact.email ?? '';
              }
            } else {
              if (contact?.email) {
                contactList.push(contact.email);
              }
            }
          });
          report.contactList = contactList;
        });
        context.commit('updateOpportunitiesReport', records.data.data);
        context.commit('updateLoadingOpportunitiesReport', false);
        return records.data;
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        context.commit('updateLoadingOpportunitiesReport', false);
      }
    },
  },

  getters: {
    marketingCampaignsReport(state: ReportState): any | null {
      return state?.marketingCampaignsReport || null;
    },
    loadingMarketingCampaignsReport(state: ReportState): boolean {
      return state?.loadingMarketingCampaignsReport;
    },
    recruitmentCampaignsReport(state: ReportState): any | null {
      return state?.recruitmentCampaignsReport || null;
    },
    loadingRecruitmentCampaignsReport(state: ReportState): boolean {
      return state?.loadingRecruitmentCampaignsReport;
    },
    mallMemosReport(state: ReportState): any | null {
      return state?.mallMemosReport || null;
    },
    loadingMallMemosReport(state: ReportState): boolean {
      return state?.loadingMallMemosReport;
    },
    storeMemosReport(state: ReportState): any | null {
      return state?.storeMemosReport || null;
    },
    loadingStoreMemosReport(state: ReportState): boolean {
      return state?.loadingStoreMemosReport;
    },
    mallAdminMarketingCampaignsReport(state: ReportState): any | null {
      return state?.mallAdminMarketingCampaignsReport || null;
    },
    loadingMallAdminMarketingCampaignsReport(state: ReportState): boolean {
      return state?.loadingMallAdminMarketingCampaignsReport;
    },
    mallAdminRecruitmentCampaignsReport(state: ReportState): any | null {
      return state?.mallAdminRecruitmentCampaignsReport || null;
    },
    loadingMallAdminRecruitmentCampaignsReport(state: ReportState): boolean {
      return state?.loadingMallAdminRecruitmentCampaignsReport;
    },
    opportunitiesReport(state: ReportState): any | null {
      return state?.opportunitiesReport || null;
    },
    loadingOpportunitiesReport(state: ReportState): boolean {
      return state?.loadingOpportunitiesReport;
    },
  },
};
