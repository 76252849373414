/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HttpService from '@/services/HttpService';
import { ContactsState, State, UserState } from '@vue/runtime-core';
import { AxiosResponse } from 'axios';
import { ActionContext } from 'vuex';
import { IContact } from '../../../../interfaces/src/v2/index';
import * as Sentry from '@sentry/vue';
import captureExceptionInSentry from './errorHandler';
import { any } from 'cypress/types/bluebird';

let errorMessage: string;

interface Response {
  success: boolean;
  data: any;
}

export const ContactsModule = {
  state(): ContactsState {
    return {
      contacts: [],
      contactGroups: [],
      contactApprovals: [],
      loadingContacts: false,
      loadingContactGroups: false,
      loadingContactApprovals: false,
    };
  },

  mutations: {
    clearContacts(state: ContactsState): void {
      state.contacts = [];
    },
    clearContactGroups(state: ContactsState): void {
      state.contactGroups = [];
    },
    clearContactApprovals(state: ContactsState): void {
      state.contactApprovals = [];
    },
    updateContacts(state: ContactsState, payload: IContact[]): void {
      state.contacts = payload;
    },
    updateContactGroups(state: ContactsState, payload: any[]): void {
      state.contactGroups = payload;
    },
    updateContactApprovals(state: ContactsState, payload: any[]): void {
      state.contactApprovals = payload;
    },
    updateLoadingContacts(state: ContactsState, payload: boolean): void {
      state.loadingContacts = payload;
    },
    updateLoadingContactGroups(state: ContactsState, payload: boolean): void {
      state.loadingContactGroups = payload;
    },
    updateLoadingContactApprovals(state: ContactsState, payload: boolean): void {
      state.loadingContactApprovals = payload;
    },
  },

  actions: {
    clearContacts(context: ActionContext<ContactsState, State>): void {
      context.commit('clearContacts');
    },

    clearContactApprovals(context: ActionContext<ContactsState, State>): void {
      context.commit('clearContactApprovals');
    },

    async fetchContactGroups(
      context: ActionContext<ContactsState, State>,
      payload: { page?: number; limit?: number; search?: string; sortKey?: string; sortDirection?: string },
    ): Promise<any> {
      context.commit('updateLoadingContactGroups', true);
      return new HttpService()
        .getContactGroups()
        .then((records: AxiosResponse<Response>) => {
          return records.data;
        })
        .catch((err: any) => {
          const errorAsAny: any = err as any;
          errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        });
    },

    async fetchContacts(
      context: ActionContext<ContactsState, State>,
      payload: { page?: number; limit?: number; search?: string; sortKey?: string; sortDirection?: string },
    ): Promise<any> {
      context.commit('updateLoadingContacts', true);
      return new HttpService()
        .getContacts(payload?.page, payload?.limit, payload?.search, payload?.sortKey, payload?.sortDirection)
        .then((records: AxiosResponse<Response>) => {
          records.data.data.forEach((contact: any) => {
            contact.sent = contact.stats.sent;
            contact.contactTypeDescription = contact?.contactType?.description;
            contact.communicationTypesDescriptions = [...new Set(contact?.communicationTypes?.map((el: any) => el.description))];
            contact.actionType = contact.actionRequired ? 'Action Required' : 'Notification Only';
            contact.inSync = contact.inSync ? 'Synced' : 'No';
            contact.last5ApprovalPercentage =
              contact?.stats?.last5ApprovalPercentage === 'N/A' ? 'N/A' : `${contact?.stats?.last5ApprovalPercentage}%`;
            contact.mallsConnected = contact?.malls?.map((el: any) => {
              return el.description;
            });

            if (contact?.contacts && contact?.contacts?.length > 0) {
              contact.allChannels = [...new Set(contact?.contacts?.map((el: any) => el.channels?.map((el: any) => el.description)).flat())];
            }
            contact.contactIds = contact?.contacts?.map((el: any) => el.contactId);
          });

          console.log('records.data', records.data);
          context.commit('updateContacts', records.data.data);
          context.commit('updateLoadingContacts', false);
          return records.data;
        })
        .catch((err: any) => {
          const errorAsAny: any = err as any;
          errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          context.commit('updateLoadingContacts', false);
        });
    },

    async fetchContactApprovals(context: ActionContext<ContactsState, State>, id: string): Promise<any> {
      context.commit('updateLoadingContactApprovals', true);
      console.log('id in approval', id);
      return new HttpService()
        .getMetaContactApprovals(id)
        .then((records: AxiosResponse<Response>) => {
          console.log('records eee', records?.data?.data);
          context.commit('updateContactApprovals', records?.data?.data);
          context.commit('updateLoadingContactApprovals', false);
          return records?.data?.data;
        })
        .catch((err: any) => {
          console.log('err', err);
          errorMessage = `Error: ${err.message || 'Unknown error'}. ID: ${id}`;
          captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
          context.commit('updateLoadingContacts', false);
        });
    },

    async saveContact(context: ActionContext<ContactsState, State>, payload: any): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .saveContact(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchContacts');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    updateContact(context: ActionContext<ContactsState, State>, payload: { payload: any; id: string }): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .updateContact(payload.payload, payload.id)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchContacts');
            resolve(records.data.data);
          })
          .catch(({ request }) => {
            errorMessage = `Error: ${request.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject(JSON.parse(request.response).message);
          });
      });
    },

    deleteContact(context: ActionContext<ContactsState, State>, payload: string): Promise<void> {
      return new Promise((resolve, reject) => {
        new HttpService()
          .deleteContact(payload)
          .then((records: AxiosResponse<Response>) => {
            context.dispatch('fetchContacts');
            resolve(records.data.data);
          })
          .catch((err: any) => {
            const errorAsAny: any = err as any;
            errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Payload: ${JSON.stringify(payload)}`;
            captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
            reject();
          });
      });
    },

    async exportContactApprovals(context: ActionContext<UserState, State>, approvals: string): Promise<any> {
      console.log('approvals in store', approvals);
      try {
        const exportData = await new HttpService().exportContactApprovals(approvals);

        return { didExport: true, data: exportData };
      } catch (err) {
        const errorAsAny: any = err as any;
        errorMessage = `Error: ${errorAsAny.message || 'Unknown error'}. Approvals: ${approvals}`;
        captureExceptionInSentry({ message: errorMessage, userId: window.localStorage.getItem('userId')!, environment: process.env.VUE_APP_ENV });
        return { didExport: false, data: null };
      }
    },
  },

  getters: {
    contacts(state: ContactsState): IContact[] {
      return state.contacts;
    },
    contactApprovals(state: ContactsState): IContact[] {
      return state.contactApprovals;
    },
    loadingContacts(state: ContactsState): boolean {
      return state.loadingContacts;
    },
    loadingContactApprovals(state: ContactsState): boolean {
      return state.loadingContactApprovals;
    },
  },
};
