
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Input from '../../../../atoms/Input/Input.vue';
import Search from '../../../../atoms/Search/Search.vue';
import Dropdown from '../../../../atoms/Dropdown/Dropdown.vue';
import Chip from '../../../../atoms/Chip/Chip.vue';
import MultiSelectDropdown from '../../../../molecules/Multi-SelectDropdown/MultiSelectDropdown.vue';
import TextArea from '../../../../atoms/Text-Area/Text-Area.vue';
import Button from '../../../../atoms/Button/Button.vue';
import Tooltip from '../../../../atoms/Tooltip/Tooltip.vue';
import Modal from '../../../../atoms/Modal/Modal.vue';
import { IChannel, IContact, ILanguage } from '../../../../../../../../interfaces/src/v2/index';
import { FormBuilder, FormControl } from '../../../../../utilities/Forms';
import { Validators } from '../../../../../utilities/Validators/Validators';
import SingleSelectInputSuggestion from '../../../../molecules/Single-Select-Input-Suggestion/Single-Select-Input-Suggestion.vue';
import { IDropdownOption } from '../../../../../utilities/Interfaces/form.interfaces';
import { ICommunicationType } from '../../../../../../../../interfaces/src/v2/CommunicationType';
import HttpService from '../../../../../services/HttpService';
import captureExceptionInSentry from '../../../../../store/errorHandler';
import { Role } from '@/enums/Role';

export default defineComponent({
  emits: {
    hasChangesMade: Boolean,
  },
  components: {
    'arc-input': Input,
    'arc-search': Search,
    'arc-dropdown': Dropdown,
    'arc-multi-select-dropdown': MultiSelectDropdown,
    'arc-single-select-input-suggestion': SingleSelectInputSuggestion,
    'arc-text-area': TextArea,
    'arc-button': Button,
    'arc-tooltip': Tooltip,
    'arc-modal': Modal,
    'arc-chip': Chip,
  },

  computed: {
    ...mapGetters([
      'modalData',
      'user',
      'access',
      'permissions',
      'mallTypes',
      'mallOwners',
      'contactTypes',
      'contactGroups',
      'communicationTypes',
      'channels',
      'countries',
      'provinces',
      'languageOptions',
    ]),
    countryFieldWidth(): string {
      let style = 'grid-column-end: span 12;';
      if (this.countryValue?.value && this.provinces.length > 0) {
        style = 'grid-column-end: span 6;';
      }
      return style;
    },
    provinceTitle(): string {
      return this.countryValue?.value === 2 ? 'Province' : 'State';
    },
    commTypeFieldWidth(): string {
      return this.commTypeValues?.length > 0 ? 'width: calc(40% - 15rem); margin-bottom: 1rem;' : 'width: 40%; margin-bottom: 1rem;';
    },
    containerType(): string {
      return this.permissions?.superAdminAccess ? 'admin-container' : 'user-container';
    },
    comTypePlaceholder(): string {
      return this.mallCommTypeValues?.length > 0 ? 'Search' : 'Select Mall Communication Types First';
    },
    channelPlaceholder(): string {
      return this.mallChannelValues?.length > 0 ? 'Search' : 'Select Mall Channels First';
    },
    updateDisabled(): boolean {
      return (
        this.permissions &&
        this.modalData?.row &&
        !this.permissions?.canUpdateAllMalls &&
        (!this.permissions?.canUpdateOwnedMalls ||
          (this.permissions?.canUpdateOwnedMalls && this.user?.userId !== this.modalData?.row?.createdByUserId))
      );
    },
    deleteDisabled(): boolean {
      return !this.permissions.superAdminAccess;
    },
    filteredLanguages() {
      let options: any = this.languageOptions;
      const languageIds: any[] = [];
      // const languageIds: any[] = this.optionalLanguagesValues.map((el: IDropdownOption) => el.value);
      if (this.primaryLanguageValue) {
        languageIds.push(this.primaryLanguageValue.value);
      }
      if (this.secondaryLanguageValue) {
        languageIds.push(this.secondaryLanguageValue.value);
      }
      return options.filter((el: any) => {
        return languageIds.includes(el.value) ? null : el;
      });
    },
  },

  watch: {
    email: {
      deep: true,
      handler() {
        this.formData.controls.e.value = this.email?.trim();
        this.validateControl('e');
      },
    },
    communicationTypes: {
      deep: true,
      handler() {
        this.mallCommTypeValues = [];
        this.communicationTypes.forEach((item: IDropdownOption) => {
          this.modalData?.row?.data?.communicationTypes?.forEach((ct: ICommunicationType) => {
            if (item.value === ct.communicationTypeId) {
              this.mallCommTypeValues.push(item);
            }
          });
        });
      },
    },
    actionRequired: {
      deep: true,
      handler() {
        this.notificationOnly = !this.actionRequired;
      },
    },
    channels: {
      deep: true,
      handler() {
        this.mallChannelValues = [];
        this.channels.forEach((item: IDropdownOption) => {
          this.modalData?.row?.data?.channels?.forEach((ch: IChannel) => {
            if (item.value === ch.channelId) {
              this.mallChannelValues.push(item);
            }
          });
        });
      },
    },
    mallTypes: {
      deep: true,
      handler() {
        this.mallTypes.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.data?.mallLocationTypeId === item?.value) {
            this.typeValue = item;
          }
        });
      },
    },
    contactTypes: {
      deep: true,
      handler() {
        this.contactTypes.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.data?.contactTypeId === item?.value) {
            this.contactTypeValue = item;
          }
        });
      },
    },
    countries: {
      deep: true,
      handler() {
        this.countries.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.data?.country === item?.description) {
            this.countryValue = item;
            this.loadProvinceOptions(this.countryValue.value);
          }
        });
      },
    },
    provinces: {
      deep: true,
      handler() {
        this.provinces.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.data?.province === item?.description) {
            this.provinceValue = item;
          }
        });
      },
    },
    mallOwners: {
      deep: true,
      handler() {
        this.mallOwners.forEach((item: IDropdownOption) => {
          if (this.modalData?.row?.data?.mallOwner && this.modalData?.row?.data?.mallOwner[0]?.mallOwnerId === item?.value) {
            this.ownerValue = item;
          }
        });
      },
    },
    languageOptions: {
      deep: true,
      handler() {
        setTimeout(() => {
          this.allowShowOptions = true;
        }, 250);
        this.optionalLanguagesValues = [];
        this.languageOptions.forEach((item: IDropdownOption) => {
          this.modalData?.row?.data?.languages?.forEach((language: ILanguage) => {
            if (language?.languageId === item?.value) {
              if (language?.Mall_Language?.languageTypeId === 1) {
                this.primaryLanguageValue = item;
                this.formData.controls.languageFlag.value = 'true';
              }
              if (language?.Mall_Language?.languageTypeId === 2) {
                this.secondaryLanguageValue = item;
              }
              if (language?.Mall_Language?.languageTypeId === 3) {
                this.optionalLanguagesValues.push(item);
              }
            }
          });
        });
      },
    },
    changesMade: {
      deep: true,
      handler() {
        this.$emit('hasChangesMade', this.changesMade);
      },
    },
  },

  async mounted(): Promise<void> {
    if (this.modalData?.mallId) {
      try {
        this.modalData.row = await this.fetchMall({ mallId: this.modalData?.mallId });
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: `Unable to fetch Mall details`,
          type: 'error',
          duration: 5000,
        });
      }
    }
    this.loadMallTypes();
    this.loadMallOwners();
    this.loadCountryOptions();
    this.loadLanguageOptions();
    this.loadContactTypes('mall');
    this.loadContactGroups();
    this.loadMallCommunicationTypes();
    this.loadChannels();
    this.setForm();
    this.loaded = true;
  },

  data(): {
    status: string;
    changesMade: boolean;
    formValid: boolean;
    promptOpen: boolean;
    promptType: string;
    submitting: boolean;
    updating: boolean;
    saving: boolean;
    expiring: boolean;
    deleting: boolean;
    typeValue: IDropdownOption | null;
    contactGroupValue: IDropdownOption | null;
    contactTypeValue: IDropdownOption | null;
    emailTypeValue: IDropdownOption | null;
    emailTypes: IDropdownOption[];
    countryValue: IDropdownOption | null;
    provinceValue: IDropdownOption | null;
    primaryLanguageValue: IDropdownOption | null;
    secondaryLanguageValue: IDropdownOption | null;
    optionalLanguagesValues: IDropdownOption[];
    allowShowOptions: boolean;
    mallCommTypeValues: IDropdownOption[];
    mallChannelValues: IDropdownOption[];
    editingContact: boolean;
    fName: string;
    lName: string;
    title: string;
    email: string;
    phone: string;
    commTypeValues: IDropdownOption[] | [];
    channelValues: IDropdownOption[] | [];
    contacts: any[];
    contactBeingEdited: any;
    formData: any;
    loaded: boolean;
    requiredImgSizes: { width: string; height: string; dimensions: string }[];
    requiredWidth: string;
    requiredHeight: string;
    ownerValue: any;
    ownerOptions: IDropdownOption[];
    actionRequired: boolean | number;
    notificationOnly: boolean | number;
  } {
    return {
      status: 'Draft',
      changesMade: false,
      formValid: true,
      promptOpen: false,
      promptType: 'save',
      submitting: false,
      updating: false,
      saving: false,
      expiring: false,
      deleting: false,
      formData: null,
      loaded: false,
      typeValue: null,
      contactGroupValue: null,
      contactTypeValue: null,
      emailTypeValue: null,
      emailTypes: [
        { value: 'to', description: 'To' },
        { value: 'cc', description: 'CC' },
      ],
      countryValue: null,
      provinceValue: null,
      primaryLanguageValue: null,
      secondaryLanguageValue: null,
      optionalLanguagesValues: [],
      allowShowOptions: false,
      mallCommTypeValues: [],
      mallChannelValues: [],
      editingContact: false,
      fName: '',
      lName: '',
      title: '',
      email: '',
      phone: '',
      commTypeValues: [],
      channelValues: [],
      contacts: [],
      contactBeingEdited: null,
      ownerValue: null,
      ownerOptions: [],
      requiredImgSizes: [],
      requiredWidth: '',
      requiredHeight: '',
      actionRequired: true,
      notificationOnly: false,
    };
  },

  methods: {
    ...mapActions([
      'closeModal',
      'clearMetadata',
      'updateClosingModal',
      'loadMallTypes',
      'loadContactTypes',
      'loadContactGroups',
      'loadMallOwners',
      'loadCountryOptions',
      'loadProvinceOptions',
      'updateModalData',
      'saveMall',
      'updateMall',
      'deleteMall',
      'loadLanguageOptions',
      'loadMallCommunicationTypes',
      'loadChannels',
      'fetchMall',
      'saveContact',
    ]),
    setForm(): void {
      // initializers
      let typeId = 0;
      let mallOwnerId = 0;
      let description = '';
      let url = '';
      let promoUrl = '';
      let jobPostingUrl = '';
      let streetAddress = '';
      let city = '';
      let country = '';
      let province = '';
      let postalCode = '';
      let facebookUrl = '';
      let instagramUrl = '';
      let twitterUrl = '';
      let notes = '';

      if (this.modalData?.row?.data) {
        typeId = this.modalData?.row?.data?.mallLocationTypeId;
        this.mallTypes?.forEach((item: IDropdownOption) => {
          if (typeId === item?.value) {
            this.typeValue = item;
          }
        });
        mallOwnerId = this.modalData?.row?.data?.mallOwnerId;
        this.ownerValue = { description: this.modalData.row?.mallOwnerDesc, value: this.modalData.row?.mallOwnerId };
        this.modalData?.row?.data?.requirements?.forEach((requirement: any) => {
          this.requiredImgSizes.push({
            width: `${requirement.width}`,
            height: `${requirement.height}`,
            dimensions: `${requirement.width}x${requirement.height}`,
          });
        });
        description = this.modalData?.row?.data?.description;
        url = this.modalData?.row?.data?.url;
        promoUrl = this.modalData?.row?.data?.promoUrl;
        jobPostingUrl = this.modalData?.row?.data?.jobPostingUrl;
        streetAddress = this.modalData?.row?.data?.streetAddress;
        city = this.modalData?.row?.data?.city;
        country = this.modalData?.row?.data?.country;
        province = this.modalData?.row?.data?.province;
        postalCode = this.modalData?.row?.data?.postalCode;
        this.optionalLanguagesValues = [];
        this.languageOptions.forEach((item: IDropdownOption) => {
          this.modalData?.row?.data?.languages?.forEach((language: ILanguage) => {
            if (language?.languageId === item?.value) {
              if (language?.Mall_Language?.languageTypeId === 1) {
                this.primaryLanguageValue = item;
              }
              if (language?.Mall_Language?.languageTypeId === 2) {
                this.secondaryLanguageValue = item;
              }
              if (language?.Mall_Language?.languageTypeId === 3) {
                this.optionalLanguagesValues.push(item);
              }
            }
          });
        });
        this.mallCommTypeValues = [];
        this.communicationTypes.forEach((item: IDropdownOption) => {
          this.modalData?.row?.data?.communicationTypes?.forEach((ct: ICommunicationType) => {
            if (item.value === ct.communicationTypeId) {
              this.mallCommTypeValues.push(item);
            }
          });
        });
        this.mallChannelValues = [];
        this.channels.forEach((item: IDropdownOption) => {
          this.modalData?.row?.data?.channels?.forEach((ch: IChannel) => {
            if (item.value === ch.channelId) {
              this.mallChannelValues.push(item);
            }
          });
        });
        this.contacts = this.modalData?.row?.data?.contacts;
        facebookUrl = this.modalData?.row?.data?.facebookUrl;
        twitterUrl = this.modalData?.row?.data?.twitterUrl;
        instagramUrl = this.modalData?.row?.data?.instagramUrl;
        notes = this.modalData?.row?.data?.notes;
      }

      // set form based on user type
      this.formData = new FormBuilder({
        e: new FormControl('', [Validators.email]),
        typeId: new FormControl(typeId),
        mallOwnerId: new FormControl(mallOwnerId),
        description: new FormControl(description, [Validators.required]),
        url: new FormControl(url),
        promoUrl: new FormControl(promoUrl),
        jobPostingUrl: new FormControl(jobPostingUrl),
        streetAddress: new FormControl(streetAddress),
        city: new FormControl(city),
        country: new FormControl(country, [Validators.required]),
        province: new FormControl(province),
        postalCode: new FormControl(postalCode),
        facebookUrl: new FormControl(facebookUrl),
        twitterUrl: new FormControl(twitterUrl),
        instagramUrl: new FormControl(instagramUrl),
        notes: new FormControl(notes),
        languageFlag: new FormControl(this.primaryLanguageValue ? 'true' : '', [Validators.required]),
      });
    },
    addRequiredImageSize() {
      if (this.requiredHeight && this.requiredWidth) {
        this.requiredImgSizes.push({
          width: `${this.requiredWidth}`,
          height: `${this.requiredHeight}`,
          dimensions: `${this.requiredWidth}x${this.requiredHeight}`,
        });
        let gcd = this.gcd(+this.requiredWidth, +this.requiredHeight);
        if ((+this.requiredWidth / gcd + '').length >= 3 || (+this.requiredHeight / gcd + '').length >= 3) {
          this.$notify({
            title: 'SOMETHING LOOKS OFF',
            text: `Please confirm that the size requirement of ${this.requiredWidth}x${this.requiredHeight} is correct as the ratio ${+this
              .requiredWidth / gcd}:${+this.requiredHeight / gcd} looks like it might be incorrect`,
            type: 'warn',
            duration: 10000,
          });
        }
        this.requiredWidth = '';
        this.requiredHeight = '';
        this.setChangesMade();
      }
    },
    removeRequiredImageSize(image: any) {
      this.requiredImgSizes = this.requiredImgSizes.filter((item: any) => item?.dimensions !== image?.dimensions);
      this.setChangesMade();
    },
    gcd(a: number, b: number): number {
      return b === 0 ? a : this.gcd(b, a % b);
    },
    getAspectRatio(a: number, b: number): string {
      let ratio = '';
      let gcd = this.gcd(a, b);
      ratio = `${a / gcd}:${b / gcd}`;
      return ratio;
    },
    updateOwner(value: IDropdownOption) {
      this.ownerValue = value;
      this.formData.controls.mallOwnerId.value = value.value;
      this.setChangesMade();
    },
    clearOwner(value: any) {
      if (value === '') {
        this.ownerValue = null;
        this.formData.controls.mallOwnerId.value = null;
        this.setChangesMade();
      }
    },
    addNewOwner(event: any): void {
      this.ownerOptions.push({
        description: event,
        value: event,
      });
      this.setChangesMade();
    },
    clearType(value: any) {
      if (value === '') {
        this.typeValue = null;
        this.formData.controls.typeId.value = null;
        this.setChangesMade();
      }
    },
    addNewType(event: any): void {
      this.ownerOptions.push({
        description: event,
        value: event,
      });
      this.setChangesMade();
    },
    updateType(value: IDropdownOption) {
      this.typeValue = value;
      this.formData.controls.typeId.value = this.typeValue?.value;
      this.setChangesMade();
    },
    updateContactGroup(value: IDropdownOption) {
      this.contactGroupValue = value;
      // inject
      new HttpService()
        .getMetaContact(value?.value as string)
        .then((results: any) => {
          const contact = results?.data?.data;
          console.log('contact', contact);
          this.contactTypes.forEach((type: IDropdownOption) => {
            if (contact?.contactTypeId === type.value) {
              this.contactTypeValue = type;
            }
          });
          this.fName = contact?.firstName ?? '';
          this.lName = contact?.lastName ?? '';
          this.title = contact?.title ?? '';
          this.email = contact?.email ?? '';
          this.phone = contact?.phoneNumber ?? '';
          this.emailTypes.forEach((type: IDropdownOption) => {
            if (contact?.emailType === type.value) {
              this.emailTypeValue = type;
            }
          });
          let vs: any[] = [];

          this.communicationTypes.forEach((item: IDropdownOption) => {
            contact?.communicationTypes?.forEach((ct: ICommunicationType) => {
              if (item.value === ct.communicationTypeId) {
                vs.push(item);
              }
            });
          });
          this.commTypeValues = vs;
          this.actionRequired = contact?.actionRequired === true || contact?.actionRequired === false ? contact?.actionRequired : true;
        })
        .catch((err: any) => {
          captureExceptionInSentry({
            message: `Error: ${err.message || 'Unknown error'}. `,
            userId: window.localStorage.getItem('userId')!,
            environment: process.env.VUE_APP_ENV,
          });
          throw new Error(`API ${err}`);
        });
    },
    clearContactGroup(value: any) {
      if (value === '') {
        this.contactGroupValue = null;
      }
    },
    updateContactType(value: IDropdownOption) {
      this.contactTypeValue = value;
      this.setChangesMade();
    },
    updateEmailType(value: IDropdownOption) {
      this.emailTypeValue = value;
      this.setChangesMade();
    },
    updateCountry(value: IDropdownOption) {
      this.countryValue = value;
      this.formData.controls.country.value = this.countryValue.description;
      this.provinceValue = null;
      this.loadProvinceOptions(this.countryValue.value);
      this.setChangesMade();
      this.validateControl('country');
    },
    updateProvince(value: IDropdownOption) {
      this.provinceValue = value;
      this.formData.controls.province.value = this.provinceValue.description;
      this.setChangesMade();
    },
    updatePrimaryLanguage(value: IDropdownOption) {
      this.primaryLanguageValue = value;
      this.formData.controls.languageFlag.value = 'true';
      this.setChangesMade();
      this.validateControl('languageFlag');
    },
    clearPrimaryLanguage(value: any) {
      if (value === '') {
        this.primaryLanguageValue = null;
        this.formData.controls.languageFlag.value = '';
        this.setChangesMade();
        this.validateControl('languageFlag');
      }
    },
    updateSecondaryLanguage(value: IDropdownOption) {
      this.secondaryLanguageValue = value;
      this.setChangesMade();
    },
    clearSecondaryLanguage(value: any) {
      if (value === '') {
        this.secondaryLanguageValue = null;
        this.setChangesMade();
      }
    },
    optionalLanguagesUpdated(value: any): void {
      this.optionalLanguagesValues = value;
      this.setChangesMade();
    },
    mallCommTypesUpdated(value: any): void {
      this.mallCommTypeValues = value;
      this.setChangesMade();
    },
    commTypesUpdated(value: any): void {
      this.commTypeValues = value;
      this.setChangesMade();
    },
    mallChannelsUpdated(value: any): void {
      this.mallChannelValues = value;
      this.setChangesMade();
    },
    channelsUpdated(value: any): void {
      this.channelValues = value;
      this.setChangesMade();
    },
    getContactType(id: number) {
      let item = '';
      this.contactTypes.forEach((type: IDropdownOption) => {
        if (type.value === id) {
          item = type.description;
        }
      });
      return item;
    },
    getCommunicationTypes(ids: any[]): string {
      let types = '';
      if (ids) {
        ids.forEach((id) => {
          this.communicationTypes.forEach((type: any) => {
            if (typeof id === 'object') {
              if (type.value === id.communicationTypeId) {
                types = types + type.description + ', ';
              }
            }
            if (typeof id === 'number') {
              if (type.value === id) {
                types = types + type.description + ', ';
              }
            }
          });
        });
        types = types.slice(0, types.length - 2);
      }
      return types;
    },
    getChannels(ids: any[]): string {
      let channels = '';
      if (ids) {
        ids.forEach((id) => {
          this.channels.forEach((channel: any) => {
            if (typeof id === 'object') {
              if (channel.value === id.channelId) {
                channels = channels + channel.description + ', ';
              }
            }
            if (typeof id === 'number') {
              if (channel.value === id) {
                channels = channels + channel.description + ', ';
              }
            }
          });
        });
        channels = channels.slice(0, channels.length - 2);
      }
      return channels;
    },
    getCSV(list: any[], key = 'description'): string {
      let csv = '';
      if (list) {
        list.forEach((item: any) => {
          csv += `${item[key]}, `;
        });
        csv = csv.slice(0, csv.length - 2);
      }
      return csv;
    },
    async addContact() {
      if (this.email && this.contactTypeValue && this.emailTypeValue && this.formData?.controls?.e?.errors?.status === 'success') {
        let emails = this.contacts.map((contact) => contact.email?.trim());
        if (emails.includes(this.email?.trim())) {
          this.$notify({
            title: 'ERROR',
            text: 'A contact with this email already exists!',
            type: 'error',
            duration: 5000,
          });
        } else {
          if (!this.contactGroupValue) {
            const newContactCreated = await this.createNewContact();
            if (newContactCreated) {
              this.addContactToContacts();
            }
          } else {
            this.addContactToContacts();
          }
        }
      } else {
        this.$notify({
          title: 'ERROR',
          text: 'Please properly fill in all required fields!',
          type: 'error',
          duration: 5000,
        });
      }
    },
    async createNewContact(): Promise<boolean> {
      let payload = {
        firstName: this.fName,
        lastName: this.lName,
        title: this.title,
        phoneNumber: this.phone,
        email: this.email?.trim(),
        contactTypeId: this.contactTypeValue?.value as number,
        actionRequired: this.actionRequired,
        emailType: this.emailTypeValue!.value,
        communicationTypes: this.commTypeValues.map((el) => {
          const communicationType = {};
          communicationType['communicationTypeId'] = el.value;
          communicationType['actionRequired'] = true;
          communicationType['notificationOnly'] = false;
          return communicationType;
        }),
        channels: this.channelValues.map((el) => el.value),
      };

      this.saving = true;
      try {
        await this.saveContact(payload);
        // this.$notify({
        //   title: 'SUCCESS',
        //   text: 'Contact saved successfully!',
        //   type: 'success',
        //   duration: 5000,
        // });
        this.saving = false;
        return true;
      } catch (err) {
        this.$notify({
          title: 'INVALID FORM',
          text:
            err === 'The resource you are trying to create already exists.'
              ? 'There is already a Contact associated with that email.'
              : 'Something went wrong.',
          type: 'warn',
          duration: 5000,
        });
        this.saving = false;
        return false;
      }
    },
    addContactToContacts(): void {
      this.contacts.push({
        firstName: this.fName,
        lastName: this.lName,
        title: this.title,
        email: this.email?.trim(),
        phoneNumber: this.phone,
        emailType: this.emailTypeValue!.value,
        actionRequired: this.actionRequired,
        notificationOnly: this.notificationOnly,
        contactTypeId: this.contactTypeValue?.value as number,
        communicationTypes: this.commTypeValues.map((el) => el.value),
        channels: this.channelValues.map((el) => el.value),
      });
      this.$notify({
        title: 'SUCCESS',
        text: 'Successfully added contact!',
        type: 'success',
        duration: 5000,
      });
      this.contactGroupValue = null;
      this.fName = '';
      this.lName = '';
      this.title = '';
      this.email = '';
      this.phone = '';
      this.contactTypeValue = null;
      this.emailTypeValue = null;
      this.commTypeValues = [];
      this.channelValues = [];
      this.actionRequired = true;
      this.notificationOnly = false;
    },
    editContact(contact: IContact) {
      this.editingContact = true;
      this.contactBeingEdited = contact;
      this.fName = contact?.firstName ?? '';
      this.lName = contact?.lastName ?? '';
      this.title = contact?.title ?? '';
      this.email = contact.email?.trim();
      this.phone = contact?.phoneNumber ?? '';
      // this.actionRequired = contact?.actionRequired === true || contact?.actionRequired === false ? contact?.actionRequired : true;
      this.actionRequired = contact?.actionRequired ? true : false;
      // this.notificationOnly = contact?.notificationOnly === true || contact?.notificationOnly === false ? contact?.notificationOnly : false;
      this.notificationOnly = !contact?.actionRequired && contact?.notificationOnly ? true : false;

      this.contactTypes.forEach((type: IDropdownOption) => {
        if (contact.contactTypeId === type.value) {
          this.contactTypeValue = type;
        }
      });
      this.emailTypes.forEach((type: IDropdownOption) => {
        if (contact.emailType === type.value) {
          this.emailTypeValue = type;
        }
      });
      let ct: IDropdownOption[] = [];
      this.communicationTypes.forEach((type: IDropdownOption) => {
        if (contact.communicationTypes) {
          contact.communicationTypes.forEach((t: any) => {
            if (typeof t === 'object') {
              if (type.value === t.communicationTypeId) {
                ct.push(type);
              }
            }
            if (typeof t === 'number') {
              if (type.value === t) {
                ct.push(type);
              }
            }
          });
        }
      });
      this.commTypeValues = ct;

      let ch: IDropdownOption[] = [];
      this.channels.forEach((type: IDropdownOption) => {
        if (contact.channels) {
          contact.channels.forEach((t: any) => {
            if (typeof t === 'object') {
              if (type.value === t.channelId) {
                ch.push(type);
              }
            }
            if (typeof t === 'number') {
              if (type.value === t) {
                ch.push(type);
              }
            }
          });
        }
      });
      this.channelValues = ch;
    },
    cancelEdit() {
      this.editingContact = false;
      this.contactBeingEdited = null;
      this.fName = '';
      this.lName = '';
      this.title = '';
      this.email = '';
      this.phone = '';
      this.contactTypeValue = null;
      this.emailTypeValue = null;
      this.commTypeValues = [];
      this.channelValues = [];
      this.actionRequired = true;
      this.notificationOnly = false;
    },
    saveEdit() {
      if (this.email && this.contactTypeValue && this.emailTypeValue && this.formData?.controls?.e?.errors?.status === 'success') {
        this.contacts.forEach((item: IContact) => {
          if (item.email?.trim() === this.contactBeingEdited.email?.trim()) {
            item.firstName = this.fName;
            item.lastName = this.lName;
            item.title = this.title;
            item.email = this.email?.trim();
            item.phoneNumber = this.phone;
            item.emailType = this.emailTypeValue?.value as string;
            item.contactTypeId = this.contactTypeValue?.value as number;
            item.communicationTypes = this.commTypeValues.map((el) => el.value);
            item.channels = this.channelValues.map((el) => el.value);
            item.actionRequired = this.actionRequired as boolean;
            item.notificationOnly = this.notificationOnly as boolean;
          }
        });
        this.contactBeingEdited = {};
        this.editingContact = false;
        this.fName = '';
        this.lName = '';
        this.title = '';
        this.email = '';
        this.phone = '';
        this.contactTypeValue = null;
        this.emailTypeValue = null;
        this.commTypeValues = [];
        this.channelValues = [];
        this.actionRequired = true;
        this.notificationOnly = false;
      } else {
        this.$notify({
          title: 'ERROR',
          text: 'Please properly fill in all required fields!',
          type: 'error',
          duration: 5000,
        });
      }
    },
    setChangesMade() {
      this.changesMade = true;
    },
    deleteContact(contact: IContact) {
      this.contacts = this.contacts.filter((item) => item.email?.trim() !== contact.email?.trim());
      this.setChangesMade();
    },

    validateControl(controlName: string): void {
      setTimeout(() => {
        this.formData.controls[controlName].validate();
        if (!this.changesMade) {
          this.setChangesMade();
        }
      }, 0);
    },
    closePrompt(): void {
      this.promptOpen = false;
    },
    openPrompt(type: string): void {
      this.promptType = type;
      this.promptOpen = true;
    },
    deletePrompt(): void {
      this.openPrompt('delete');
    },
    async delete(): Promise<void> {
      this.deleting = true;
      this.promptOpen = false;

      try {
        await this.deleteMall(this.modalData?.row?.data?.mallId);
        this.deleting = false;
        this.promptOpen = false;
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
          this.$notify({
            title: 'SUCCESS',
            text: 'Mall deleted successfully!',
            type: 'success',
            duration: 5000,
          });
        }, 500);
      } catch (err) {
        this.deleting = false;
        this.promptOpen = false;
        this.$notify({
          title: 'ERROR',
          text: 'Unable to delete mall.',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    cancelPrompt(): void {
      if (this.changesMade) {
        this.openPrompt('cancel');
      } else {
        this.updateClosingModal(true);
        setTimeout(() => {
          this.closeModal();
          this.clearMetadata();
          this.updateClosingModal(false);
        }, 500);
      }
    },
    cancel(): void {
      this.promptOpen = false;
      this.updateClosingModal(true);
      setTimeout(() => {
        this.closeModal();
        this.clearMetadata();
        this.updateClosingModal(false);
      }, 500);
    },
    async save(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors && this.primaryLanguageValue) {
        let languages: any[] = [];
        if (this.primaryLanguageValue) {
          languages.push({
            languageId: this.primaryLanguageValue.value,
            languageTypeId: 1,
          });
        }
        if (this.secondaryLanguageValue) {
          languages.push({
            languageId: this.secondaryLanguageValue.value,
            languageTypeId: 2,
          });
        }
        if (this.optionalLanguagesValues && this.optionalLanguagesValues?.length > 0) {
          this.optionalLanguagesValues?.forEach((item: IDropdownOption) => {
            languages.push({
              languageId: item.value,
              languageTypeId: 3,
            });
          });
        }
        let requirements: any[] = [];
        if (this.requiredImgSizes && this.requiredImgSizes?.length > 0) {
          this.requiredImgSizes?.forEach((requirement: any) => {
            requirements.push({
              height: requirement.height,
              width: requirement.width,
              aspectRatio: this.getAspectRatio(requirement.width, requirement.height),
            });
          });
        }
        let cts = this.mallCommTypeValues.map((type: any) => type.value);
        let chs = this.mallChannelValues.map((type: any) => type.value);
        const payload = {
          mallLocationTypeId: this.formData?.controls?.typeId?.value,
          description: this.formData?.controls?.description?.value,
          facebookUrl: this.formData?.controls?.facebookUrl?.value,
          twitterUrl: this.formData?.controls?.twitterUrl?.value,
          instagramUrl: this.formData?.controls?.instagramUrl?.value,
          notes: this.formData?.controls?.notes?.value,
          streetAddress: this.formData?.controls?.streetAddress?.value,
          city: this.formData?.controls?.city?.value,
          country: this.formData?.controls?.country?.value,
          province: this.formData?.controls?.province?.value,
          postalCode: this.formData?.controls?.postalCode?.value,
          url: this.formData?.controls?.url?.value,
          mallOwnerId: this.formData?.controls?.mallOwnerId?.value,
          promoUrl: this.formData?.controls?.promoUrl?.value,
          jobPostingUrl: this.formData?.controls?.jobPostingUrl?.value,
          languages: languages,
          requirements: requirements,
          contacts: this.contacts,
          communicationTypes: cts,
          channels: chs,
        };
        this.saving = true;

        try {
          await this.saveMall(payload);
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Mall saved successfully!',
            type: 'success',
            duration: 5000,
          });
          this.changesMade = false;
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: 'Unable to create Mall',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Mall form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
    async update(): Promise<void> {
      this.formData.markAllAsTouched();
      if (!this.formData.hasErrors && this.primaryLanguageValue) {
        let languages: any[] = [];
        if (this.primaryLanguageValue) {
          languages.push({
            languageId: this.primaryLanguageValue.value,
            languageTypeId: 1,
          });
        }
        if (this.secondaryLanguageValue) {
          languages.push({
            languageId: this.secondaryLanguageValue.value,
            languageTypeId: 2,
          });
        }
        if (this.optionalLanguagesValues && this.optionalLanguagesValues?.length > 0) {
          this.optionalLanguagesValues?.forEach((item: IDropdownOption) => {
            languages.push({
              languageId: item.value,
              languageTypeId: 3,
            });
          });
        }
        let requirements: any[] = [];
        if (this.requiredImgSizes && this.requiredImgSizes?.length > 0) {
          this.requiredImgSizes?.forEach((requirement: any) => {
            requirements.push({
              height: requirement.height,
              width: requirement.width,
              aspectRatio: this.getAspectRatio(requirement.width, requirement.height),
            });
          });
        }
        const cts = this.mallCommTypeValues.map((type: any) => type.value);
        const chs = this.mallChannelValues.map((type: any) => type.value);
        this.contacts.forEach((contact: IContact) => {
          if (contact?.communicationTypes && contact?.communicationTypes.length > 0) {
            if (typeof contact?.communicationTypes[0] === 'object') {
              contact.communicationTypes = contact?.communicationTypes?.map((i) => i.communicationTypeId);
            }
          }

          if (contact?.channels && contact?.channels.length > 0) {
            if (typeof contact?.channels[0] === 'object') {
              contact.channels = contact?.channels?.map((i) => i.channelId);
            }
          }
        });
        const payload = {
          mallLocationTypeId: this.formData?.controls?.typeId?.value,
          description: this.formData?.controls?.description?.value,
          facebookUrl: this.formData?.controls?.facebookUrl?.value,
          twitterUrl: this.formData?.controls?.twitterUrl?.value,
          instagramUrl: this.formData?.controls?.instagramUrl?.value,
          notes: this.formData?.controls?.notes?.value,
          streetAddress: this.formData?.controls?.streetAddress?.value,
          city: this.formData?.controls?.city?.value,
          country: this.formData?.controls?.country?.value,
          province: this.formData?.controls?.province?.value,
          postalCode: this.formData?.controls?.postalCode?.value,
          url: this.formData?.controls?.url?.value,
          mallOwnerId: this.formData?.controls?.mallOwnerId?.value,
          promoUrl: this.formData?.controls?.promoUrl?.value,
          jobPostingUrl: this.formData?.controls?.jobPostingUrl?.value,
          languages: languages,
          requirements: requirements,
          contacts: this.contacts,
          communicationTypes: cts,
          channels: chs,
        };
        this.saving = true;

        try {
          await this.updateMall({
            payload,
            mallId: this.modalData?.row?.data?.mallId,
          });
          this.saving = false;
          this.$notify({
            title: 'SUCCESS',
            text: 'Mall updated successfully!',
            type: 'success',
            duration: 5000,
          });
          this.closeModal();
          this.clearMetadata();
        } catch (err) {
          this.saving = false;
          this.$notify({
            title: 'ERROR',
            text: 'Unable to update Mall',
            type: 'error',
            duration: 5000,
          });
        }
      } else {
        this.$notify({
          title: 'INVALID FORM',
          text: 'Mall form is incomplete or invalid!',
          type: 'warn',
          duration: 5000,
        });
      }
    },
  },
});
