import { PaginationState, State } from '@vue/runtime-core';
import { ActionContext } from 'vuex';

export const PaginationModule = {
  state(): PaginationState {
    return {
      page: 1,
      limit: 25,
      search: '',
      sortKey: 'id',
      sortDirection: 'DESC',
      closedStores: undefined,
    };
  },

  mutations: {
    updatePage(state: PaginationState, payload: number): void {
      state.page = payload;
    },
    updateLimit(state: PaginationState, payload: number): void {
      state.limit = payload;
    },
    updateSearch(state: PaginationState, payload: string): void {
      state.search = payload;
    },
    updateSortKey(state: PaginationState, payload: string): void {
      state.sortKey = payload;
    },
    updateSortDirection(state: PaginationState, payload: string): void {
      state.sortDirection = payload;
    },
    updateClosedStores(state: PaginationState, payload: boolean): void {
      state.closedStores = payload;
    },
    resetPagination(state: PaginationState): void {
      state.page = 1;
      state.limit = 25;
      state.search = '';
      state.sortKey = 'id';
      state.sortDirection = 'DESC';
      state.closedStores = undefined;
    },
  },

  actions: {
    updatePaginationPage(context: ActionContext<PaginationState, State>, payload: number): void {
      context.commit('updatePage', payload);
    },
    updatePaginationLimit(context: ActionContext<PaginationState, State>, payload: number): void {
      context.commit('updateLimit', payload);
    },
    updatePaginationSearch(context: ActionContext<PaginationState, State>, payload: string): void {
      context.commit('updateSearch', payload);
    },
    updatePaginationSortKey(context: ActionContext<PaginationState, State>, payload: string): void {
      context.commit('updateSortKey', payload);
    },
    updatePaginationSortDirection(context: ActionContext<PaginationState, State>, payload: string): void {
      context.commit('updateSortDirection', payload);
    },
    updatePaginationClosedStores(context: ActionContext<PaginationState, State>, payload: string): void {
      context.commit('updateClosedStores', payload);
    },
    resetPaginationState(context: ActionContext<PaginationState, State>): void {
      context.commit('resetPagination');
    },
  },

  getters: {
    paginationPage(state: PaginationState): number | undefined {
      return state.page;
    },
    paginationLimit(state: PaginationState): number | undefined {
      return state.limit;
    },
    paginationSearch(state: PaginationState): string | undefined {
      return state.search;
    },
    paginationSortKey(state: PaginationState): string | undefined {
      return state.sortKey;
    },
    paginationSortDirection(state: PaginationState): string | undefined {
      return state.sortDirection;
    },
    paginationClosedStores(state: PaginationState): boolean | undefined {
      return state.closedStores;
    },
  },
};
